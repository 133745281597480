* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.active {
  background: rgba(128, 128, 128, 0.2);
  display: inline;
}

li:hover {
  background: rgba(128, 128, 128, 0.2);
  display: inline;
}

a {
  padding: 10px;
  margin: 5px;
}

.homeButtons:hover {
  background-color: black;
  color: white;
  transition: background-color 0.5s ease, color 0.5s ease;
}
